:root{
   --footer-height: 8vh;
   --footer-color: linear-gradient(rgba(5, 17, 49, 0.2), rgba(5, 17, 49, 1));
   --nav-height: 5vh;
   --body-padding: 5vh;
   --body-footer-padding: 5vh;
}

.Page-container.About {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
}

.About-title-decoration {
   display: flex;
   flex-flow: column nowrap;
   align-items: center;
   text-align: center;
   font-size: 3rem;
   background-color: rgba(28, 39, 59, 0.6);
   border-radius: 15px;
   margin-top: calc(2vh + var(--nav-height));
   padding: 0.5vh;
}

.About-body-decoration {
   flex: 1 1 1;
   text-align: center;
   background-color: rgba(5, 17, 49, 0.75);
   border-radius: 2rem;
}

.About-body{
   text-align: left;
   display: flex;
   flex-direction: column;
   align-items: center;
   overflow-y: auto;
   padding: 0 var(--body-padding) var(--body-padding) var(--body-padding);
}

.About-body::-webkit-scrollbar{
   width: 0.5vw;
   z-index: 1;
}

.About-body::-webkit-scrollbar-track {
   background: #23232e;
   z-index: 1;
}

.About-body::-webkit-scrollbar-thumb {
   background: var(--app-link-color);
   border-radius: 0.25vw;
   z-index: 1;
}

.About-body-intro {
   text-align: left;
   text-decoration: none;
   margin-right: 3vw;
   margin-left: 3vw;
}

.About-body-intro-wrapper{
   width: 100%;
   display: flex;
   flex-direction: row;
   align-items: flex-start;
}

.About-body-main {
   display: flex;
   text-align: left;
   margin-right: 3.5vw;
   margin-left: 3.5vw;
}

.About-intro{
   margin: 0;
   font-size: 3.25vh;
   text-align: center;
}

.About-icon-container {
   display: flex;
   flex-flow: row wrap;
   align-items: center;
   justify-content: space-evenly;
   margin-top: 0.75vh;
}

.About-icon{
   display: flex;
   flex: 1 1 1;
   margin: 10px;
   align-items: center;
   justify-content: center;
   background-color: #1c273b;
   border-radius: 50%;
}

/* Mobile */
@media screen and (max-width: 767px) {
   [class*="About-footer-icon"] {
      min-width: 6.25vh;
      max-width: 6.25vh;
      min-height: 6.25vh;
      max-height: 6.25vh;
      margin: 5px;
   }
}
@media screen and (min-width: 768px) {
   [class*="About-footer-icon"] {
      min-width: 3rem;
      min-height: 3rem;
   }
}