:root {
  font-size: 16px;
  font-family: "Open Sans", serif;
  --text-primary: #b6b6b6;
  --text-secondary: #ececec;
  --bg-primary: #23232e;
  --bg-secondary: #141418;
  --transition-speed: 600ms;
  --expanded-height: 8rem;
  --navbar-size: 5rem;
  --expanded-width: 16rem;
  --text-left-offset: -200vw;

  overflow: hidden;
}

.navbar {
  position: fixed;
  background-color: var(--bg-primary);
  transition: width 600ms ease;
  overflow: hidden;
  z-index: 10;
}

.navbar-nav {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.nav-item {
  width: 100%;
}

.nav-link {
  display: flex;
  align-items: center;
  height: var(--navbar-size);
  color: var(--text-primary);
  text-decoration: none;
  filter: grayscale(100%) opacity(0.7);
  transition: var(--transition-speed);
}

.nav-link:hover {
  filter: grayscale(0%) opacity(1);
  background: var(--bg-secondary);
  color: var(--text-secondary);
}

.link-text {
  display: inline;
  margin-left: 1.5rem;
  transition: var(--transition-speed);
  transition-delay: calc(var(--transition-speed)/3);
}

.nav-link svg {
  width: 2rem;
  min-width: 2rem;
  margin: 0 1.5rem;
}

.logo {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1rem;
  text-align: center;
  color: var(--text-secondary);
  background: var(--bg-secondary);
  font-size: 1.5rem;
  letter-spacing: 0.3ch;
  width: 100%;
}

.logo svg {
  transform: rotate(0deg);
  transition: var(--transition-speed);
}

/* Large screens */
@media only screen and (min-width: 769px) {
  .App-body {
    padding-top: 5rem;
    height: calc(100vh - 5rem);
  }

  .navbar {
    top: 0;
    width: 100vw;
    height: 5rem;
    transition: var(--transition-speed);
  }

  .logo {
    display: none;
  }

  .navbar-nav {
    flex-direction: row;
  }

  .nav-link {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .nav-link:hover {
    height: var(--expanded-height);
  }

  .link-text {
    font-size: 0;
    margin-top: 1rem;
    margin-left: 0;
  }

  .nav-item:last-child {
    margin-top: unset;
    margin-left: auto;
  }

  .App-body {
    margin: 0;
    bottom: 0;
  }

  .navbar:hover {
    height: var(--expanded-height);
  }

  .navbar:hover .link-text {
    display: inline;
    font-size: 2rem;
  }

  .nav-item.expand{
    display: none;
    flex-direction: row-reverse;
    transition: var(--transition-speed);
  }
}

/* Small screens */
@media only screen and (max-width: 768px) {
  .nav-link {
    width: 100%;
  }
  .App-body {
    padding-left: 5rem;
    width: calc(100vw - 5rem);
  }

  .navbar {
    top: 0;
    width: 5rem;
    height: 100vh;
    overflow-y: hidden;
  }

  .link-text{
    font-size: 0;
  }

  .navbar.expanded {
    width: var(--expanded-width);
  }

  .navbar.expanded .link-text {
    display: inline;
    font-size: 2rem;
  }

  .navbar.expanded .nav-item.expand{
    display: flex;
    transition: var(--transition-speed);
  }

  .nav-link.expand{
    display: flex;
    flex-direction: row-reverse;
  }

  .expand svg{
    transform: rotate(0deg);
    transition: var(--transition-speed);
    transition-delay: calc(var(--transition-speed)/3);
  }

  .navbar.expanded .expand svg{
    transform: rotate(-180deg);
  }
}
