.Home-Recent-Posts{
   background-color: rgba(0, 0, 0, 0.4);
   width: 55vw;
   min-height: 25vh;
   max-height: 60vh;
   border-radius: 10%;
   box-shadow: 0 0 5vh 1vh rgba(0, 0, 0, 0.8);
   margin-bottom: 2vh;
   padding: 0;
   position: relative;
   z-index: 0;
   -webkit-transition: all 0.3s ease;
   transition: all 0.3s ease;
   display: flex;
   flex-flow: column;
   justify-content: flex-start;
   align-items: center;
   overflow-x: hidden;
   overflow-y: auto;
}

@media screen and (max-width: 767px) {
   [class*="Home-Recent-Posts"] {
      max-height: 75vh;
   }
}

.Home-Recent-Posts-Wrapper {
   min-height: 25vh;
   max-height: 60vh;
}

.Home-Recent-Posts::-webkit-scrollbar {
   display: none;
}

.Home-Recent-Posts-Highlight {
   position: sticky;
   bottom: -1px;
   width: 55vw;
   min-height: 10vh;
   z-index: 2;
   background: linear-gradient(0deg, rgb(43, 158, 255) 0%, rgba(1,1,1,0) 30%);
}

.Home-Recent-Post{
   width: 40vw;
   height: auto;
   background-color: #2a3b5c;
   border-radius: 6px;
   margin: 20px;
   padding: 10px 5px 10px 5px;
   position: relative;
   z-index: 1;
   -webkit-transition: all 0.3s ease;
   transition: all 0.3s ease;
   display: flex;
   flex-flow: column;
   align-items: center;
   justify-content: flex-start;
}

.Home-Recent-Post:hover {
   box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 1);
   background-color: rgba(58,77,116,0.9);
}

.Home-Recent-Post-Title {
   margin: 0;
   font-size: larger;
}

.Home-Recent-Post-Collection {
   display: flex;
   flex-direction: row;
   align-items: center;
}

@media screen and (max-width: 767px) {
   [class*="Home-Recent-Post-Collection"] {
      flex-direction: column;
   }
}

.Home-Recent-Post-Description {
   width: auto;
   margin: 0;
   overflow-x: hidden;
   overflow-y: visible;
}