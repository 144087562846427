@import url("https://fonts.googleapis.com/css2?family=Alumni+Sans");
:root {
  --background-image-home: url("https://jonahmc-bucket.s3.amazonaws.com/Images/Mountain_Lake_Background.jpg");
  --background-image-project: url("https://jonahmc-bucket.s3.amazonaws.com/Images/Mountain_Range_Background.jpg");
  --background-image-darken: linear-gradient( rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25) );
  --app-link-color: rgb(159, 224, 255);
  --app-link-color-darkened: rgb(50, 75, 117);
  --app-link-color-alt: rgb(0, 73, 213);
  --home-page-background-color: rgb(8, 13, 29);
  --project-page-background-color: rgba(0, 39, 146);
  --about-page-background-color: rgba(5, 17, 49, 1);
  --header-label-height: 10vh;
  --block-image-scale: 18vw;
}

body {
  font-family: 'Alumni Sans', sans-serif;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.App-body {
  background-image: none;
  height: calc(100vh - var(--header-label-height));
  width: 100vw;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .App-body {
    min-height: unset;
    height: 100vh;
    min-width: calc(97vw - var(--header-label-height));
    overflow: hidden;
  }
}

.Page-container {
  background-image: none;
  background-color: rgba(255, 255, 255, 0);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center top;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: calc(10px + 2vmin);
  color: white;
  overflow-y: auto;
  overflow-x: hidden;
}

.Page-container::-webkit-scrollbar{
  width: 0.5vw;
  z-index: 1;
}

.Page-container::-webkit-scrollbar-track {
  background: #23232e;
  z-index: 1;
}

.Page-container::-webkit-scrollbar-thumb {
  background: var(--app-link-color);
  border-radius: 0.25vw;
  z-index: 1;
}

.App.Home {
  background: var(--home-page-background-color);
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), var(--home-page-background-color)), var(--background-image-home);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.App.About {
  background: var(--about-page-background-color);
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), var(--home-page-background-color)), var(--background-image-home);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  
  align-items: stretch;
  justify-content: flex-start;
}

.App.Project {
  background: var(--project-page-background-color);
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), var(--home-page-background-color)), var(--background-image-project);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.App-header {
  background-color: rgba(28,39,59,0.76);
  min-height: 5vh;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header-label {
  background-color: rgba(22,31,47,0.55);
  min-width: 30vw;
  min-height: 5vh;
}

.App-header-button-container {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
  justify-content: flex-end;
  min-width: 40vw;
  min-height: 5vh;
}

.App-header-button {
  background-color: rgba(22,31,47,1);
  min-width: 10vw;
  min-height: 5vh;
  color: white;
  border: none;
}

.App-header-button-link {
  width: 100%;
  color: white;
  text-decoration: none;
}

.App-header-button a:hover {
  background-color: rgba(28,39,59,1);
}

.App-link {
  color: var(--app-link-color);
}

.App-link:visited{
  color: var(--app-link-color);
}

.Page-content-block {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  width: 55vw;
  background-color: rgba(22,31,47,1);
  font-family: inherit;
  margin: 0;
  margin-top: 5vh;
  padding: 1vw;
  z-index: 1;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.Page-content-block-text {
  color: white;
  margin-left: 3vh;
  margin-right: 3vh;
  font-size: 1.75rem;
  width: 100%;
}

.Page-content-block-list {
  font-size: 1.75rem;
  margin-top: 0;
  width: 100%;
}

.Page-content-block2 {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  width: 25vw;
  background-color: rgba(222, 234, 255, 0.6);
  color:rgba(22,31,47,1);
  font-family: inherit;
  font-weight: 600;
  margin-top: 1vh;
  margin-left: 2vw;
  padding: 0.5rem;
  z-index: 1;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

/*.Page-content-block2:hover{
  background-color: rgba(222, 234, 255, 0.9)
}*/

@media screen and (max-width: 768px) {
  [class*="Page-content-block-text"] {
    font-size: 1rem;
  }
}

.Image-combo {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

@media screen and (max-width: 768px) /* Mobile */ {
  [class*="Image-combo"] {
    flex-direction: column;
  }
}

.Page-content-block-wide {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  width: 60vw;
  min-height: 20vh;
  background-color: rgba(22,31,47,1);
  font-family: inherit;
  margin: 0;
  padding: 20px;
  z-index: 1;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.Page-content-block-wide.Project {
  flex-flow: column;
}

.Project-list-items {
  display: flex;
  flex-flow: row wrap;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  font-family: inherit;
}

@media screen and (max-width: 767px) {
  [class*="Project-list-items"] {
    flex-flow: column nowrap;
    width: 100%;
    margin-bottom: -50vh;
  }
}

.Project-list-items.Other {
  margin: 5vh;
}

@media screen and (max-width: 767px) {
  [class*="Project-list-items Other"] {
    flex-flow: column wrap;
  }
}

.Project-list-text {
  margin-left: 1.5vw;
  margin-right: 1.5vw;
}

.Project-list-subtext {
  margin: 0 0 0 0.5vw;
  font-size: 1.5rem;
  font-weight: normal;
}

.Project-list-item {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  flex: 1 1 1;
  font-size: 2.5rem;
  margin: 2.5vw;
  padding-right: 2.5vw;
  padding-left: 2.5vw;
  background-color: rgba(22,31,47,1);
  color: var(--app-link-color);
  text-decoration: none;
  border-radius: 3vh;
  font-family: inherit;
  overflow: hidden;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

@media screen and (min-width: 768px) {
  [class*="Project-list-item"] {
    min-width: 25vw;
    min-height: 10vh;
  }
}

@media screen and (max-width: 767px) /* Mobile */ {
  [class*="Project-list-item"] {
    min-width: 10vw;
    min-height: 25vh;
    flex-direction: column;
  }

  [class*="Project-list-item Other"] {
    min-width: unset;
    min-height: unset;
    font-size: 1.75rem;
    /* border: 1px solid red; */
  }
}

.Project-list-item:hover {
  background-color: rgba(255,255,255,0.9);
  color: var(--app-link-color-darkened);
  flex: 1.25 1 1;
}

.Project-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.Project-main {
  display: flex;
  flex-flow: column;
  justify-content: center;
  min-width: 50vw;
}

@media screen and (min-width: 768px) {
  .Project-spacer {
    margin: 25vh;
  }
}

.Project-embed {
  flex: 1 1 0;
  padding-top: 2.5vh;
  padding-bottom: 2.5vh;
}

.Project-h2 {
  margin-bottom: 0;
}

.Project-description {
  padding-bottom: 5rem;
}

.Project-description-paragraph {
  margin-top: 2vh;
}

.Link-container {
  display: flex;
  flex-flow: row wrap;
  align-content: space-around;
  align-items: center;
  text-align: left;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  width: 15vw;
  height: 10vh;
  background-color: rgba(22,31,47,1);
  font-family: inherit;
  margin: 0;
  padding: 1vw 1vw 1vw 2vw;
  z-index: 1;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.Link{
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
}

.Link-name{
  font-size: 1.75rem;
  color: white;
  text-decoration: none;
  justify-content: right;
  display: flex;
  margin-right: 1rem;
}

@media screen and (max-width: 768px) /* Desktop */ {
  [class*="Link-container"] {
    width: 30vw;
    height: 7.5vh;
  }
  [class*="Link-name"] {
    font-size: 1rem;
  }
}

.Block-Wrapper {
  min-width: 80vw;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  border-radius: 10px;
  box-shadow: 0 0 2vw 1vw rgba(0, 0, 0, 0.4);
  background: rgba(5, 17, 49, 0.7);
  font-family: inherit;
  margin: 2vh 5vw;
  z-index: 1;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.Block-Wrapper.Vertical {
  flex-flow: column nowrap;
}

@media screen and (max-width: 767px) {
  [class*="Block-Wrapper"] {
    flex-direction: column;
    min-width: 50vw;
  }
}

.Block-Header {
  display: flex;
  flex: 1 1 0;
  font-size: 10vh;
}

.Block-Body {
  display: flex;
  flex: 1 1 0;
  margin: 0vh 3vw;
  font-size: 4.25vh;
}

.Block-Body.Hidden {
  display: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.Block-Body.NotHidden {
  display: flex;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.Block-Body.Centered {
  justify-content: center;
}

@media screen and (max-width: 767px) {
  [class*="Block-Body"] {
    font-size: 3.5vh;
  }
}

.Block-Image {
  width: var(--block-image-scale);
  height: var(--block-image-scale);
  margin: 1rem;
  border-radius: 1.5rem;
  box-shadow: 0 0 0.5rem 0.25rem rgba(8, 24, 12, 0.8);
}

@media screen and (min-width: 768px) {
  [class*="Block-Image Smart"] {
    width: calc(sqrt(2) * var(--block-image-scale));
    height: var(--block-image-scale);
  }
}

@media screen and (max-width: 767px) {
  [class*="Block-Image Smart"] {
    width: var(--block-image-scale);
    height: calc(sqrt(2) * var(--block-image-scale));
  }
}

.Block-Image.Small {
  width: calc(var(--block-image-scale) / 2);
  height: calc(var(--block-image-scale) / 2);
}

.Block-Image.Horizontal {
  width: calc(sqrt(2) * var(--block-image-scale));
  height: var(--block-image-scale);
}

.Block-Image.Horizontal.Home {
  margin: 10px;
}

.Block-Image.Vertical {
  width: var(--block-image-scale);
  height: calc(sqrt(2) * var(--block-image-scale));
}

/* *{
   border: 1px solid red;
} */
